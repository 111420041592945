import React from "react";
import Header from "./header/Header";
import Main from "./main/Main";

function Home() {
  return (
    <>
      <Header />
      {/* <Main /> */}
    </>
  );
}

export default Home;
