const signinData = [
  {
    img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAMAAACf4xmcAAAANlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC3dmhyAAAAEXRSTlMAX98/H7+fYBDvzzCPgG+vT3j4jU8AAAC9SURBVDjLrdHbDoMgEARQ5bqCYPn/n21DMUNwu6EJ82TwZBbd7c/oMxylhGsXkS13VPqpUiMNGl45kObELjh6qtheHZ07nyzcc3Ln9Kh0PTa4I1931rL66MGOkVmc9lPjwL43z58nUh0bt9GOnc9QHEMkpnimpU0hNDDPKrsNISxJ3FYSPwEJc86otY6c7FDoWqNNrEPia98j4UfCcVnvotYXnBSHPpFNzw1zcy365liRGBkTwMTCpcyDzbg33P4lMy5O5vkAAAAASUVORK5CYII=",
    name: "SSO",
  },
  {
    img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAMAAABMOI/cAAAANlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC3dmhyAAAAEXRSTlMA79+/ECA/gG+fXzCPcM+vT5pWFbQAAACXSURBVCjPlZHhDsMgCIRFBauu2+79X3aNdDEruKTfD2PuROUIv+QSt+DAEXh7xqGDHX0HUBcF0SvIQOKrxvlYNgm5S56yJADPxnlskOTUC66UoTcYiLUpo/dFgaaSjB7DgIzxUMO56Z4xc3R+q6G8sHh9g6Vo2A5JO1wYAkv7H0o3xnfAu/MppWpjkc7OJ4VoTFoqkZ7/AI4hGRToOffeAAAAAElFTkSuQmCC",
    name: "Apple",
  },
  {
    img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAABNVBMVEUAAAD/PgBJsUT/OwANdNRHsEtHsEpHsEoNddT/PABKsEp1tSr/YwD/OwD/wwD/OwD/wgD/PAD/OwBPsUj/wQAMdNX/PAD/PQBWskNIr0r/wgD/PABKsEj/OgBIr0j/PwD/OgAPddb/QQD/wwD/QABHr0tJr0r/wgAMdNX/wgAMdNb/OwALddU5oW9Hr0z/PAD/OwBnsz9HsEr/OwAOdNP/PABIsUv/iAALdNX/OwD/PQBMsEhIsEoMddVIsEr/OwD/PQD/OgDRuxL/vQAMdNVJr0n/QAALddT/wwD/vwAWd89Nskj/PwBMrEX/vwBAr0j/xQD/RwAQcM//TAAMdNX/OwBHsEv/wgBAqFwSecgTfMMagbYljJ1PsUdnsz6StyyvuiDdvw7/sQD/kQD/dwD/WAD/RgBL4Ss6AAAAVHRSTlMAEBLf+e/ix8W2gggH+PfvyMSal5eNfWNhYF9fRkY+OiMZFQgE+vbv7Obl49/Y2NbLv769uLe0tLKsqKebl5WThYOAgG5paEhIRDo1NSUkIB8ZEAp/9d8/AAABMklEQVQ4y8XQ13LCMBBA0cWEDkmAhBI6pPfee+8VrUjv+f9PiNkRjCzZfuU8WTt3ZmVBB0ULidkYY7H4TsEp8aR6WNtEymPXpM1E5k3rTYJp9tRmnenWBq3Nrk2zpDTHTFjIlR+i5VzI/AwpN696GZkpgVDyqg0c/FATl+Z3agNj/PvP/OcquDjjnH/9sktw08ebVsBVL0UDINRlXUEx7aboWo+oElNOonpELFHNKZLX3bqu0y8uC8pP8DkHikWKtsTp1Gw+XrFobc7r5EQcayP8HRGnDbmpTFIzCi37b9gUkKr7YJ30Q4vhQ+K/AKE49UjNeAXaMigsZ68ikZvsPGLjqRkdgiSJmsYzLZNtoO5lFRRhPUqC5shvTXwZsGGE/VISNsBBfjswhDgc2MxHoHP+AVnAlPxGJFfYAAAAAElFTkSuQmCC",
    name: "Google",
  },
  {
    img: "https://img.icons8.com/fluency/344/meta.png",
    name: "Meta",
  },
];
export default signinData;
